import { useState } from "react";

const Quiz = () => {
  const [step, setStep] = useState(1);

  return (
    <>
      {step < 7 && (
        <div className="modal-overlay center">
          <section class="main_question-wrapper">
            <div class="quiz_header">
              <div class="quiz_logo-block">
                <img
                  loading="lazy"
                  src="quiz/images/felo_logo_blue.svg"
                  alt=""
                  class="quiz_logo-felo"
                />
              </div>
            </div>
            <div class="quiz_container w-container">
              {step == 1 ? (
                <div class="first_quiz-block">
                  <div class="quiz_progress-block">
                    <div class="quiz_progress-inner">
                      <div class="quiz_progress-percentage">
                        <div class="quiz_progress-percentage-text">25%</div>
                      </div>
                      <div class="quiz_progress-close">
                        <a class="quiz_progress-close-block w-inline-block">
                          <img
                            loading="lazy"
                            src="quiz/images/close_withspace.svg"
                            alt=""
                            class="quiz_progress-close-icon"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <img
                    sizes="(max-width: 479px) 90vw, 300px"
                    srcset="quiz/images/dr_one-p-500.png 500w, quiz/images/dr_one.png 563w"
                    alt=""
                    src="quiz/images/dr_one.png"
                    loading="lazy"
                    class="first_slider-image"
                  />
                  <div class="quiz_inner-text-block">
                    <div class="quiz_inner-text">
                      Lorem ipsum dolor sit amet consectetur. Enim ullamcorper
                      bibendum vulputate est nullam vitae.
                    </div>
                  </div>
                  <button
                    data-target="drawer"
                    class="quiz_continue-btn w-inline-block"
                    onClick={() => setStep(2)}
                  >
                    <div class="quiz_continue-btn-text">Continue</div>
                  </button>
                </div>
              ) : step == 2 ? (
                <div class="second_quiz-block">
                  <div class="quiz_progress-block is-50">
                    <div class="quiz_progress-inner">
                      <div class="quiz_progress-percentage">
                        <div class="quiz_progress-percentage-text">50%</div>
                      </div>
                      <div class="quiz_progress-close">
                        <button
                          href="#"
                          class="quiz_progress-close-block w-inline-block"
                        >
                          <img
                            loading="lazy"
                            src="quiz/images/close_withspace.svg"
                            alt=""
                            class="quiz_progress-close-icon"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="quiz_question-wrapper">
                    <div class="question_image-block">
                      <img
                        loading="lazy"
                        src="quiz/images/doc_q-image.png"
                        alt=""
                        class="question_image"
                      />
                    </div>
                    <div class="quiz_question-block">
                      <div class="question_text-block">
                        <div class="question_text">
                          What position do you sleep in ?
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="quiz_content-wrapper">
                    <div class="quiz_inner-block">
                      <div class="for_block-one">
                        <div class="quiz_inner-block-item">
                          <div class="qq_inner-block">
                            <div class="qq_inner-text">Side Sleeper</div>
                          </div>
                          <img
                            loading="lazy"
                            src="quiz/images/gir1.svg"
                            alt=""
                            class="girl-image"
                          />
                        </div>
                        <div class="quiz_inner-block-item is-active">
                          <div class="quiz_selector-block">
                            <img
                              loading="lazy"
                              src="quiz/images/green_selector.svg"
                              alt=""
                              class="green_selector"
                            />
                          </div>
                          <div class="qq_inner-block is-active">
                            <div class="qq_inner-text">Combination Sleeper</div>
                          </div>
                          <img
                            loading="lazy"
                            src="quiz/images/gir2.svg"
                            alt=""
                            class="girl-image"
                          />
                        </div>
                      </div>
                      <div class="for_block-two">
                        <div class="quiz_inner-block-item">
                          <div class="qq_inner-block">
                            <div class="qq_inner-text">Stomach Sleeper</div>
                          </div>
                          <img
                            loading="lazy"
                            src="quiz/images/gir3.svg"
                            alt=""
                            class="girl-image"
                          />
                        </div>
                        <div class="quiz_inner-block-item">
                          <div class="qq_inner-block">
                            <div class="qq_inner-text">Back Sleeper</div>
                          </div>
                          <img
                            loading="lazy"
                            src="quiz/images/gir4.svg"
                            alt=""
                            class="girl-image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    data-target="drawer"
                    class="quiz_continue-btn w-inline-block"
                    onClick={() => setStep(3)}
                  >
                    <div class="quiz_continue-btn-text">Continue</div>
                  </button>
                </div>
              ) : step == 3 ? (
                <div class="third_quiz-block">
                  <div class="quiz_progress-block is-70">
                    <div class="quiz_progress-inner">
                      <div class="quiz_progress-percentage">
                        <div class="quiz_progress-percentage-text">75%</div>
                      </div>
                      <div class="quiz_progress-close">
                        <a
                          href="#"
                          class="quiz_progress-close-block w-inline-block"
                        >
                          <img
                            loading="lazy"
                            src="quiz/images/close_withspace.svg"
                            alt=""
                            class="quiz_progress-close-icon"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="quiz_question-wrapper">
                    <div class="question_image-block">
                      <img
                        loading="lazy"
                        src="quiz/images/doc_q-image.png"
                        alt=""
                        class="question_image"
                      />
                    </div>
                    <div class="quiz_question-block">
                      <div class="question_text-block">
                        <div class="question_text">Do you suffer from ?</div>
                      </div>
                    </div>
                  </div>
                  <div class="quiz_content-wrapper">
                    <div class="quiz_inner-block">
                      <div class="for_block-one">
                        <div class="quiz_inner-block-item">
                          <div class="qq_inner-block">
                            <div class="qq_inner-text">Headache</div>
                          </div>
                          <img
                            loading="lazy"
                            src="quiz/images/head1.svg"
                            alt=""
                            class="girl-image"
                          />
                        </div>
                        <div class="quiz_inner-block-item is-active">
                          <div class="quiz_selector-block">
                            <img
                              loading="lazy"
                              src="quiz/images/green_selector.svg"
                              alt=""
                              class="green_selector"
                            />
                          </div>
                          <div class="qq_inner-block is-active">
                            <div class="qq_inner-text">Neck Pain</div>
                          </div>
                          <img
                            loading="lazy"
                            src="quiz/images/head2.svg"
                            alt=""
                            class="girl-image"
                          />
                        </div>
                      </div>
                      <div class="for_block-two">
                        <div class="quiz_inner-block-item is-slim">
                          <div class="qq_inner-block">
                            <div class="qq_inner-text">Back Pain</div>
                          </div>
                          <img
                            loading="lazy"
                            src="quiz/images/head3.svg"
                            alt=""
                            class="girl-image"
                          />
                        </div>
                        <div class="quiz_inner-block-item is-slim">
                          <div class="qq_inner-block">
                            <div class="qq_inner-text">Toss &amp; Turn</div>
                          </div>
                          <img
                            loading="lazy"
                            src="quiz/images/head4.svg"
                            alt=""
                            class="girl-image"
                          />
                        </div>
                        <div class="quiz_inner-block-item is-slim">
                          <div class="qq_inner-block">
                            <div class="qq_inner-text">None</div>
                          </div>
                          <img
                            loading="lazy"
                            src="quiz/images/head5.svg"
                            alt=""
                            class="girl-image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    data-target="drawer"
                    class="quiz_continue-btn w-inline-block"
                    onClick={() => setStep(4)}
                  >
                    <div class="quiz_continue-btn-text">Continue</div>
                  </button>
                </div>
              ) : step == 4 ? (
                <div class="forth_quiz-block">
                  <div class="quiz_progress-block is-99">
                    <div class="quiz_progress-inner">
                      <div class="quiz_progress-percentage">
                        <div class="quiz_progress-percentage-text">99%</div>
                      </div>
                      <div class="quiz_progress-close">
                        <a
                          href="#"
                          class="quiz_progress-close-block w-inline-block"
                        >
                          <img
                            loading="lazy"
                            src="quiz/images/close_withspace.svg"
                            alt=""
                            class="quiz_progress-close-icon"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="quiz_question-wrapper">
                    <div class="question_image-block">
                      <img
                        loading="lazy"
                        src="quiz/images/doc_q-image.png"
                        alt=""
                        class="question_image"
                      />
                    </div>
                    <div class="quiz_question-block">
                      <div class="question_text-block">
                        <div class="question_text">
                          What your preferred firmness ?
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="quiz_content-wrapper">
                    <div class="inner_block-radio">
                      <div class="inner_block-radio-items is-active">
                        <div class="ibr-left">
                          <div class="radio_select-circle is-active"></div>
                          <div class="ibr-text">Soft</div>
                        </div>
                        <div class="ibr-right">
                          <img
                            loading="lazy"
                            src="quiz/images/firm1.svg"
                            alt=""
                            class="firm-img"
                          />
                        </div>
                      </div>
                      <div class="inner_block-radio-items">
                        <div class="ibr-left">
                          <div class="radio_select-circle"></div>
                          <div class="ibr-text">Medium</div>
                        </div>
                        <div class="ibr-right">
                          <img
                            loading="lazy"
                            src="quiz/images/firm2.svg"
                            alt=""
                            class="firm-img"
                          />
                        </div>
                      </div>
                      <div class="inner_block-radio-items">
                        <div class="ibr-left">
                          <div class="radio_select-circle"></div>
                          <div class="ibr-text">Firm</div>
                        </div>
                        <div class="ibr-right">
                          <img
                            loading="lazy"
                            src="quiz/images/firm3.svg"
                            alt=""
                            class="firm-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    data-target="drawer"
                    class="quiz_continue-btn w-inline-block"
                    onClick={() => setStep(5)}
                  >
                    <div class="quiz_continue-btn-text">Continue</div>
                  </button>
                </div>
              ) : step === 5 ? (
                <div class="analyse_quiz-block">
                  <div class="analyse_inner-block">
                    <div class="analyse_circle">
                      <h1 class="analyse_percentage">67%</h1>
                      <div class="analyse_sub-text">Analyzing Results</div>
                    </div>
                  </div>
                  <div class="analyse_review-item">
                    <div class="analyse_review-wrapper">
                      <div class="analyse_review-block is-left">
                        <div class="ana_rev-head">
                          <div class="testi_image-block">
                            <img
                              loading="lazy"
                              src="quiz/images/rem1_1rem1.png"
                              alt=""
                              class="testi_img"
                            />
                            <div class="testi_auth-name">IAmMoorer</div>
                          </div>
                          <div class="analyse_review-star-block">
                            <img
                              loading="lazy"
                              src="quiz/images/Review.svg"
                              alt=""
                              class="analyse_review-star-icon"
                            />
                          </div>
                        </div>
                        <div class="analyse_review-text">
                          Pellentesque habitant morbi tristique senectus et
                          netus et malesuada fames ac turpis egestas.
                        </div>
                      </div>
                      <div class="analyse_review-block is-center">
                        <div class="ana_rev-head">
                          <div class="testi_image-block">
                            <img
                              loading="lazy"
                              src="quiz/images/rem1_1rem1.png"
                              alt=""
                              class="testi_img"
                            />
                            <div class="testi_auth-name">IAmMoorer</div>
                          </div>
                          <div class="analyse_review-star-block">
                            <img
                              loading="lazy"
                              src="quiz/images/Review.svg"
                              alt=""
                              class="analyse_review-star-icon"
                            />
                          </div>
                        </div>
                        <div class="analyse_review-text">
                          Pellentesque habitant morbi tristique senectus et
                          netus et malesuada fames egestas.Pellentesque habitant
                          morbi tristique senectus et netus et malesuada fames
                          ac turpis egestas.
                        </div>
                      </div>
                      <div class="analyse_review-block is-right">
                        <div class="ana_rev-head">
                          <div class="testi_image-block">
                            <img
                              loading="lazy"
                              src="quiz/images/rem1_1rem1.png"
                              alt=""
                              class="testi_img"
                            />
                            <div class="testi_auth-name">IAmMoorer</div>
                          </div>
                          <div class="analyse_review-star-block">
                            <img
                              loading="lazy"
                              src="quiz/images/Review.svg"
                              alt=""
                              class="analyse_review-star-icon"
                            />
                          </div>
                        </div>
                        <div class="analyse_review-text">
                          Pellentesque habitant morbi tristique senectus et
                          netus et malesuada fames ac turpis egestas.
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    data-target="drawer"
                    class="quiz_continue-btn w-inline-block"
                    onClick={() => setStep(6)}
                  >
                    <div class="quiz_continue-btn-text">Continue</div>
                  </button>
                </div>
              ) : (
                <div class="result_quiz-block">
                  <div class="result_quiz-inner-block">
                    <img
                      sizes="(max-width: 479px) 90vw, 380px"
                      srcset="quiz/images/dr_happy-p-500.png 500w, quiz/images/dr_happy.png 750w"
                      alt=""
                      src="quiz/images/dr_happy.png"
                      loading="lazy"
                      class="last_result-image"
                    />
                    <div class="quiz_result-inner-text-block">
                      <h1 class="result_heading-text">It’s a Match!</h1>
                      <div class="result_inner-text">
                        There’s a <span class="text-span-157">94% chance</span>{" "}
                        that
                        <span class="text-span-157">
                          Felo will improve
                        </span>{" "}
                        your sleep
                      </div>
                    </div>
                  </div>
                  <button
                    data-target="drawer"
                    class="quiz_continue-btn w-inline-block"
                  >
                    <div
                      class="quiz_continue-btn-text"
                      onClick={() => setStep(7)}
                    >
                      Continue
                    </div>
                  </button>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Quiz;
